import React from 'react'

const Footer = () => {
  return (
    <footer>
      <p>&copy; 2020 archdesign</p>
    </footer>
  )
}

export default Footer
